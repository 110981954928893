import * as React from "react";
import Side from "./Side";
import "./App.scss";
import Particles from "react-tsparticles";

export interface IAppProps {}

export interface IAppState {}

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <>
      <Particles
        id="tsparticles"
        options={{
          interactivity: {
            detectsOn: "canvas",
            
          },
         
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 120,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
           
            move: {
              direction: "none",
              enable: true,
              outMode: "out",
              random: true,
              speed: 4,
              straight: false,
              
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "polygon",
            },
            size: {
              random: true,
              value: 3,
            },
          },
          // detectRetina: true,
        }}
      />
      <div id="container-main">
        {/* <Side /> */}
        <div className="content">
          {/* Content <div className="test"></div> */}
          <div >
            <p style={{fontSize: 30, fontWeight: "bold"}}>Em Construção</p>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default App;
